var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"XZ3bsEtmHQEf-Ca3Ju7sQ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN: string =
  "https://f668bee950ad48bebe0a13b2a01f43d6@o1287378.ingest.sentry.io/6506361";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REPO_ENV,
  release: require("package.json").version,
  tracesSampleRate: 1.0
});
